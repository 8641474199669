import React from 'react'
import T from 'prop-types'
import {
  Grid,
  Typography,
  withStyles,
  Paper,
  Box,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { SectionTitle } from 'gatsby-components'
import { useTranslation } from 'react-i18next'
import { uniq } from 'lodash'
import classnames from 'classnames'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import { COLOR_TIERS } from './config'

function Table({ classes, theme, tableDef }) {
  const { t } = useTranslation()

  const indicatorList = uniq(tableDef.table.rows.map(r => r.indicator))

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <SectionTitle
            barColor={theme.palette.secondary.main}
            noWrap
            className={classes.maturityProfileSectionTitle}
          >
            {t(tableDef.title)}
          </SectionTitle>
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={5}>
          <Typography variant="body1" className={classes.description}>
            {t(tableDef.description)}
          </Typography>
        </Grid>
        <Grid item xs={7} />
        <Grid
          container
          item
          alignItems="baseline"
          className={classes.indicatorsList}
        >
          {indicatorList.map((indicator, i) => (
            <React.Fragment key={`indicator_${i}`}>
              <Grid item>
                <CheckBoxIcon
                  htmlColor={COLOR_TIERS[i]}
                  className={classes.checkBoxIcon}
                />
              </Grid>
              <Grid item>
                <Typography variant="h2">{t(indicator)}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.maturityProfileTableHeader}>
          <div className={classes.tablePadding}>
            <MuiTable>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    className={classnames(
                      classes.removeBorder,
                      classes.removePadding
                    )}
                  >
                    <Box className={classes.maturityProfileTableTitleBox}>
                      <Typography variant="h2">
                        {t(tableDef.table.title)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    align="left"
                    colSpan={3}
                    className={classes.removeBorder}
                  >
                    <Typography
                      variant="body1"
                      className={classes.maturityProfileTableHeaderDescription}
                    >
                      {t(tableDef.table.description)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableDef.table.rows.map((row, i) => (
                  <TableRow
                    key={row.name}
                    className={classes.maturityProfileTableRows}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      width="20%"
                      className={classes.maturityProfileTableRowCells}
                      style={{
                        background: `linear-gradient(to right, ${
                          COLOR_TIERS[indicatorList.indexOf(row.indicator)]
                        } 8px, ${theme.palette.quaternary.main} 2%)`,
                      }}
                    >
                      <Typography variant="h2">{row.indicator}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      width="15%"
                      className={classes.maturityProfileTableRowCells}
                    >
                      <Typography variant="h2">{row.range}</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      width="15%"
                      className={classes.maturityProfileTableRowCells}
                    >
                      <Typography variant="h2">{t(row.status)}</Typography>
                    </TableCell>
                    <TableCell align="left" width="40%">
                      <Typography variant="body1">
                        {t(row.description)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </MuiTable>
          </div>
        </Paper>
      </Grid>
    </Grid>
  )
}

Table.propTypes = {
  tableDef: T.object.isRequired,
}

const styles = theme => ({
  description: {
    fontSize: 21,
  },
  indicatorsList: {
    marginTop: theme.spacing(3),
    '& *:nth-child(odd)': {
      marginRight: theme.spacing(1),
    },
    '& *:nth-child(even)': {
      marginRight: theme.spacing(10),
    },
  },
  checkBoxIcon: {
    background: `radial-gradient(${theme.palette.background.default} 50%, transparent 40%)`,
    width: '16px',
    height: '16px',
  },
  maturityProfileTableHeader: {
    backgroundColor: theme.palette.quaternary.dark,
  },
  tablePadding: {
    margin: theme.spacing(10, 2, 0),
    paddingBottom: theme.spacing(2),
  },
  maturityProfileSectionTitle: {
    marginBottom: theme.spacing(3),
  },
  maturityProfileTableHeaderDescription: {
    opacity: 0.6,
    textTransform: 'none',
  },
  maturityProfileTableTitleBox: {
    backgroundColor: theme.palette.quaternary.accent,
    padding: theme.spacing(1.5, 0),
    borderRadius: '2px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)',
    '& .MuiTypography-root': {
      fontSize: 21,
      textTransform: 'none',
    },
    marginRight: '6px',
  },
  maturityProfileTableRows: {
    borderRadius: '2px',
    '& .MuiTableCell-root': {
      backgroundColor: theme.palette.quaternary.main,
      verticalAlign: 'top',
      margin: theme.spacing(4),
      color: theme.palette.background.default,
      borderBottom: `1px solid ${theme.palette.quaternary.dark}`,
      padding: theme.spacing(0),
      '& > *': {
        margin: theme.spacing(2),
      },
    },
  },
  removeBorder: {
    border: 'none',
  },
  removePadding: {
    padding: 0,
  },
  maturityProfileTableRowCells: {
    borderRight: `13px solid ${theme.palette.quaternary.dark}`,
  },
})

export default withStyles(styles, { withTheme: true })(Table)
