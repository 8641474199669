import React from 'react'
import T from 'prop-types'
import { Grid } from '@material-ui/core'
import Img from 'gatsby-image'

const Image = ({
  classes,
  image,
  className,
  imageLink,
  imageProps = {},
  ...props
}) => {
  const inner = <Img fluid={image} className={className} {...imageProps} />

  return (
    <Grid item {...props}>
      {imageLink ? <a href={imageLink}>{inner}</a> : inner}
    </Grid>
  )
}

Image.propTypes = {
  image: T.object.isRequired,
  imageProps: T.object,
  className: T.string,
}

export default Image
