import React from 'react'
import T from 'prop-types'
import { Grid, withStyles } from '@material-ui/core'
import { PaddedContainer, BoxCarousel } from 'gatsby-components'
import { get } from 'lodash'
import classnames from 'classnames'

import { Brochure, Table, Image, RichText } from '.'

function SectionContainer({
  classes,
  theme,
  fixedImageSelector,
  namespace,
  index,
  data = [],
  additionalContainerProps = {},
  additionalContainerGridProps = {},
  sectionBackgroundColor,
  isDarkBackground,
}) {
  const { style = {}, ...containerProps } = additionalContainerProps

  return (
    <PaddedContainer
      className={classnames(classes.sectionContainer, {
        [classes.darkContainer]: isDarkBackground,
      })}
      {...containerProps}
      style={{
        ...style,
        backgroundColor: get(theme.palette, sectionBackgroundColor),
      }}
    >
      <Grid container {...additionalContainerGridProps}>
        {data.map((component, i) => {
          const { type, brochureDef, ...componentProps } = component
          switch (type) {
            case 'carousel':
              return (
                <Grid item {...componentProps}>
                  <BoxCarousel
                    bodyIndex={index}
                    dataIndex={i}
                    namespace={namespace}
                    fixedImageSelector={fixedImageSelector}
                    boxes={componentProps.boxes}
                    className={classes.carousel}
                  />
                </Grid>
              )
            case 'brochure':
              return (
                <Brochure
                  {...componentProps}
                  brochureImage={fixedImageSelector(
                    brochureDef.show !== false && 'brochure'
                  )}
                  brochureShopUrl={brochureDef.webshop.url}
                  brochureButtonText={brochureDef.webshop.buttonText}
                />
              )
            case 'table':
              return <Table {...componentProps} />
            case 'image': {
              const { fixedImageType, ...props } = componentProps

              return (
                <Image
                  image={fixedImageSelector(fixedImageType)}
                  {...props}
                  className={classnames({
                    [classes.modelContainer]: fixedImageType === 'model',
                  })}
                />
              )
            }
            case 'richText': {
              const { titleBarColor, ...props } = componentProps

              return (
                <RichText
                  namespace={namespace}
                  bodyIndex={index}
                  dataIndex={i}
                  titleBarColor={get(theme.palette, titleBarColor)}
                  {...props}
                />
              )
            }
            default:
              return <Grid item {...componentProps}></Grid>
          }
        })}
      </Grid>
    </PaddedContainer>
  )
}

SectionContainer.propTypes = {
  fixedImageSelector: T.func.isRequired,
  data: T.array,
  additionalContainerProps: T.object,
  additionalContainerGridProps: T.object,
  sectionBackgroundColor: T.string,
  isDarkBackground: T.bool,
}

const styles = theme => ({
  sectionContainer: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
  },
  modelContainer: {
    margin: theme.spacing(10),
    marginTop: theme.spacing(13),
  },
  darkContainer: {
    '& .MuiTypography-root': {
      color: theme.palette.background.default,
    },
  },
  carousel: {
    marginRight: theme.spacing(10),
  },
})

export default withStyles(styles, { withTheme: true })(SectionContainer)
