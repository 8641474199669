import React from 'react'
import T from 'prop-types'
import { Grid, withStyles, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Img from 'gatsby-image'

function Brochure({
  classes,
  brochureShopUrl,
  brochureImage,
  brochureButtonText,
  ...props
}) {
  const { t } = useTranslation()

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      spacing={0}
      {...props}
    >
      <Grid item>
        {brochureImage ? (
          <Img className={classes.brochureImage} fixed={brochureImage} />
        ) : (
          <br />
        )}
      </Grid>
      <Grid item>
        <a href={brochureShopUrl}>
          <Button
            className={classes.brochureDownloadButton}
            color="secondary"
            variant="contained"
            fullWidth
          >
            {t(brochureButtonText || 'Buy brochure')}
          </Button>
        </a>
      </Grid>
    </Grid>
  )
}

Brochure.propTypes = {
  brochureShopUrl: T.string.isRequired,
  brochureImage: T.object.isRequired,
}

const styles = theme => ({
  brochureImage: {
    margin: theme.spacing(-0.75, 0, 3),
  },
  brochureDownloadButton: {
    padding: theme.spacing(1.5, 3.5),
    marginBottom: theme.spacing(2),
  },
})

export default withStyles(styles)(Brochure)
