import React from 'react'
import T from 'prop-types'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { SectionTitle } from 'gatsby-components'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'

import { COLOR_TIERS } from './config'

function RichText({
  classes,
  theme,
  titleBarColor,
  titleGridSize,
  namespace,
  bodyIndex,
  dataIndex,
  title,
  body,
  ...props
}) {
  const { t } = useTranslation()

  return (
    <Grid container item {...props}>
      {title && (
        <Grid item xs={titleGridSize} className={classes.sectionTitleGrid}>
          <SectionTitle
            barColor={titleBarColor || theme.palette.primary.dark}
            noWrap
          >
            {t(`findOutMore.body.${bodyIndex}.data.${dataIndex}.title`, {
              ns: namespace,
              defaultValue: title,
              keySeparator: '.',
            })}
          </SectionTitle>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body1">
          <ReactMarkdown
            className={classes.markdownStyling}
            source={t(`findOutMore.body.${bodyIndex}.data.${dataIndex}.body`, {
              ns: namespace,
              defaultValue: body,
              keySeparator: '.',
            })}
          />
        </Typography>
      </Grid>
    </Grid>
  )
}

RichText.propTypes = {
  titleBarColor: T.string,
  titleGridSize: T.num,
  title: T.string,
  body: T.string.isRequired,
}

const styles = theme => ({
  sectionTitleGrid: {
    marginBottom: theme.spacing(2),
  },
  markdownStyling: {
    '& > ul': {
      listStyle: 'none',
      padding: 0,
      marginLeft: 35,
      textIndent: '-1.8em',
      '& > li': {
        '&::before': {
          color: COLOR_TIERS[0],
          fontFamily: 'Material Icons',
          content: '"\\e834\\00a0\\00a0\\00a0"',
          fontSize: 16,
          position: 'relative',
          top: '2px',
          background: `radial-gradient(${theme.palette.background.default} 50%, transparent 40%) no-repeat center left/16px 15px`,
        },
      },
    },
    '& h3': {
      ...theme.typography.h3,
      color: theme.palette.primary.dark,
    },
    '& h4': {
      ...theme.typography.body1,
      fontSize: 21,
      margin: '1em 0',
    },
  },
})

export default withStyles(styles, { withTheme: true })(RichText)
