import React, { useEffect, useContext } from 'react'
import { graphql, Link } from 'gatsby'
import { Grid, Typography, withStyles, Button } from '@material-ui/core'
import { PaddedContainer, AuthContext, QuoteCarousel } from 'gatsby-components'
import BackgroundImage from 'gatsby-background-image'
import { useTranslation } from 'react-i18next'
import { loadCSS } from 'fg-loadcss'
import Img from 'gatsby-image'
import { get } from 'lodash'

import SEO from '../components/SEO'
import { SectionContainer } from '../components/FindOutMoreSections'

function FindOutMore({ classes, data, pageContext: { lens, ...pageContext } }) {
  const {
    heroBanner,
    modelImageAssets: { nodes: modelImageAssets },
    brochureImage,
    sectionImages: { nodes: sectionImages },
    carouselImages: { nodes: carouselImages },
    quoteImages: { nodes: quoteImages },
  } = data

  const { modelImageName } = pageContext

  const { t, i18n } = useTranslation()
  const lang = i18n.language || 'en'

  const modelImage =
    modelImageAssets.find(img => img.name === `${modelImageName}_${lang}`) ||
    modelImageAssets.find(img => img.name === `${modelImageName}_en`)

  const fixedImageSelector = (fixedImageType, imgName) => {
    switch (fixedImageType) {
      case 'model':
        return modelImage.childImageSharp.fluid
      case 'brochure':
        return brochureImage.childImageSharp.fixed
      case 'carousel':
        return carouselImages.find(({ name }) => name === imgName)
          .childImageSharp.fluid
      default: {
        const img = sectionImages.find(({ name }) => name === fixedImageType)
        return img && img.childImageSharp.fluid
      }
    }
  }

  const { getUserTokenData } = useContext(AuthContext)
  const { isAuthenticated } = getUserTokenData()

  useEffect(() => {
    loadCSS('https://fonts.googleapis.com/icon?family=Material+Icons')
  }, [])

  const questionnaire = lens.assessmentTypes.find(
    x => x.lensVersionOf === 'questionnaire-2020'
  )
  const bma = lens.assessmentTypes.find(
    x => x.lensVersionOf === 'efqm-2020-advanced'
  )

  const partnershipImage = get(lens, 'findOutMore.header.partnershipImageName')

  return (
    <BackgroundImage
      className={classes.heroContainer}
      fluid={heroBanner.childImageSharp.fluid}
    >
      <SEO title={t(`${lens.name} Lens Find Out More`)} />
      <div className={classes.header}>
        <PaddedContainer className={classes.heroDescription}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.headerGrid}
          >
            <Grid item xs={12} sm={7}>
              <Grid container spacing={3} className={classes.titleGrid}>
                <Grid item xs={6}>
                  <Typography variant="h1">
                    {t('findOutMore.header.title', {
                      defaultValue: lens.findOutMore.header.title,
                      ns: lens.translationNameSpace,
                      keySeparator: '.',
                    })}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                direction="column"
                justify="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={7}>
                  <Typography>
                    {t('findOutMore.header.description', {
                      defaultValue: lens.findOutMore.header.description,
                      ns: lens.translationNameSpace,
                      keySeparator: '.',
                    })}
                  </Typography>
                </Grid>
                {partnershipImage && (
                  <Grid item xs={7}>
                    <Img
                      fluid={fixedImageSelector(partnershipImage.name)}
                      style={partnershipImage.style}
                    />
                  </Grid>
                )}
                <Grid item className={classes.getStartedGrid} xs={12}>
                  <Typography>
                    {t('findOutMore.header.getStarted', {
                      defaultValue: lens.findOutMore.header.getStarted,
                      ns: lens.translationNameSpace,
                      keySeparator: '.',
                    })}
                  </Typography>
                  <Grid
                    container
                    className={classes.getStartedButtonGrid}
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        component={Link}
                        to={`/assessment/${questionnaire.key}`}
                        color="secondary"
                        variant="outlined"
                        fullWidth
                      >
                        {t('Questionnaire')}
                      </Button>
                    </Grid>
                    {isAuthenticated && (
                      <Grid item>
                        <Button
                          component={Link}
                          to={`/assessment/${bma.key}`}
                          color="secondary"
                          variant="contained"
                          fullWidth
                        >
                          {t(
                            'find-out-more.button.bma',
                            'Business matrix advanced'
                          )}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5}>
              <QuoteCarousel
                namespace={lens.translationNameSpace}
                quotes={lens.findOutMore.header.quotes.map(q => ({
                  ...q,
                  image: quoteImages.find(img => img.name === q.image),
                }))}
                className={classes.carousel}
              />
            </Grid>
          </Grid>
        </PaddedContainer>
      </div>
      {lens.findOutMore.body.map((section, i) => (
        <SectionContainer
          namespace={lens.translationNameSpace}
          index={i}
          key={`section_${i}`}
          fixedImageSelector={fixedImageSelector}
          {...section}
        />
      ))}
    </BackgroundImage>
  )
}

const styles = theme => ({
  header: {
    position: 'relative',
    marginBottom: theme.spacing(5),
  },
  heroContainer: {
    backgroundSize: '100%',
    backgroundPosition: 'top left',
  },
  heroDescription: {
    marginTop: theme.spacing(6),
  },
  titleGrid: { marginBottom: theme.spacing(2) },
  headerGrid: { marginTop: theme.spacing(5) },
  getStartedGrid: { marginTop: theme.spacing(2) },
  getStartedButtonGrid: { marginTop: theme.spacing(1) },
  carousel: {
    marginRight: theme.spacing(10),
  },
  '@global': {
    main: {
      marginBottom: '0 !important',
    },
  },
})

export const query = graphql`
  query findOutMorePageQuery(
    $heroImageName: String!
    $brochureImageName: String!
    $modelImageAssets: [String]!
    $sectionImages: [String]!
    $carouselImages: [String]!
    $quoteImages: [String]!
  ) {
    heroBanner: file(name: { eq: $heroImageName }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    brochureImage: file(name: { eq: $brochureImageName }) {
      childImageSharp {
        fixed(height: 310, width: 223) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    modelImageAssets: allFile(filter: { name: { in: $modelImageAssets } }) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    sectionImages: allFile(filter: { name: { in: $sectionImages } }) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    carouselImages: allFile(filter: { name: { in: $carouselImages } }) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    quoteImages: allFile(filter: { name: { in: $quoteImages } }) {
      nodes {
        name
        childImageSharp {
          fixed(width: 65, height: 65, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

export default withStyles(styles)(FindOutMore)
